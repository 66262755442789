<template>
    <div class="main-box">
        <div class="pd-box">
<!--            <a-form :model="formPostDemand" :label-col="labelCol" :wrapper-col="wrapperCol">-->
                <div v-for="(formItem, formi) in formLists" :key="formi">
                    <div class="content">
                        <div class="con-item">
                            <a-form-item :model="formPostDemand" :label-col="labelCol" :wrapper-col="wrapperCol" :label="formItem.PROJECT_NAME" :style="{fontWeight:(formItem.PROJECT_NAME==='一、营业总收入'||formItem.PROJECT_NAME==='二、营业总成本'||formItem.PROJECT_NAME==='三、营业利润（亏损以“-”号填列）'
                            ||formItem.PROJECT_NAME==='四、利润总额（亏损总额以“-”号填列）'||formItem.PROJECT_NAME==='五、净利润（净亏损以“-”号填列）')?'bold':'',fontSize:(formItem.PROJECT_NAME!==''?'15px':'12px')}"
                                                    :label-align="(formItem.PROJECT_NAME==='一、营业总收入'||formItem.PROJECT_NAME==='二、营业总成本')?'left':''">
                                <a-input-number v-model:value="formItem.AMOUNT" :disabled="!isEdit ? true : false"  style="width: 100%;margin-left: 50px;" placeholder="请输入本年金额"></a-input-number>
                            </a-form-item>
                        </div>
                        <div class="con-item">
                            <a-form-item :model="formPostDemand" :label-col="labelCol" :wrapper-col="wrapperCol">
                                <a-input-number style="visibility: hidden;"></a-input-number>
                            </a-form-item>
                        </div>
                        <div class="con-item" v-for="(cItem, ci) in formItem.childrenList" :key="ci">
                            <a-form-item :model="formPostDemand" :label-col="labelCol" :wrapper-col="wrapperCol" :label="cItem.PROJECT_NAME"
                                         :style="{fontSize: (cItem.PROJECT_NAME==='加：公允价值变动收益（损失以“-”号填列）'?'12px':'12px')} ">
                                <a-input-number v-model:value="cItem.AMOUNT" :disabled="!isEdit ? true : false" style="width: 100%;margin-left: 50px;" placeholder="请输入本年金额"></a-input-number>
                            </a-form-item>
                        </div>
                    </div>
                </div>
<!--            </a-form>-->
        </div>
        <div class="con-box" style="text-align: center;">
            <a-button type="primary" @click="onSave">保存当前</a-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters([
            'orgId',
            'loginType',
            'isAuth',
            'isEdit',
            'y'
        ])
    },
    watch: {
        y: function (newVal) {
            this.getLYList(newVal)
        }
    },
    data () {
        return {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 16
            },
            formLists: []
        }
    },
    methods: {
        // 获取利润表
        getLYList (y) {
            this.$store.dispatch('getLYList', { showCount: 30, currentPage: 1, ENTERPRISE_ID: this.orgId, YEAR: y, tm: new Date().getTime() }).then(res => {
                this.formLists = []
                if (res.result === 'success') {
                    let k = 0
                    for (let i in res.varList) {
                        if (res.varList[i].PROJECT_NAME === '二、营业总成本') {
                            k = i
                            break
                        } else {
                            if (i == 0) {
                                this.formLists.push({
                                    AMOUNT: res.varList[i].AMOUNT,
                                    ENTERLIRUNBIAO_ID: res.varList[i].ENTERLIRUNBIAO_ID,
                                    PROJECT_NAME: res.varList[i].PROJECT_NAME,
                                    PROJECT_PROPERTY: res.varList[i].PROJECT_PROPERTY,
                                    YEAR: res.varList[i].YEAR,
                                    childrenList: []
                                })
                            } else {
                                this.formLists[0].childrenList.push({
                                    AMOUNT: res.varList[i].AMOUNT,
                                    ENTERLIRUNBIAO_ID: res.varList[i].ENTERLIRUNBIAO_ID,
                                    PROJECT_NAME: res.varList[i].PROJECT_NAME,
                                    PROJECT_PROPERTY: res.varList[i].PROJECT_PROPERTY,
                                    YEAR: res.varList[i].YEAR
                                })
                            }
                        }
                    }
                    for (let i = k, len = res.varList.length; i < len; i++) {
                        if (res.varList[i].PROJECT_NAME === '三、营业利润（亏损以“-”号填列）') {
                            k = i
                            break
                        } else {
                            if (i == k) {
                                this.formLists.push({
                                    AMOUNT: res.varList[i].AMOUNT,
                                    ENTERLIRUNBIAO_ID: res.varList[i].ENTERLIRUNBIAO_ID,
                                    PROJECT_NAME: res.varList[i].PROJECT_NAME,
                                    PROJECT_PROPERTY: res.varList[i].PROJECT_PROPERTY,
                                    YEAR: res.varList[i].YEAR,
                                    childrenList: []
                                })
                            } else {
                                this.formLists[1].childrenList.push({
                                    AMOUNT: res.varList[i].AMOUNT,
                                    ENTERLIRUNBIAO_ID: res.varList[i].ENTERLIRUNBIAO_ID,
                                    PROJECT_NAME: res.varList[i].PROJECT_NAME,
                                    PROJECT_PROPERTY: res.varList[i].PROJECT_PROPERTY,
                                    YEAR: res.varList[i].YEAR
                                })
                            }
                        }
                    }
                    for (let i = k, len = res.varList.length; i < len; i++) {
                        if (res.varList[i].PROJECT_NAME === '四、利润总额（亏损总额以“-”号填列）') {
                            k = i
                            break
                        } else {
                            if (i == k) {
                                this.formLists.push({
                                    AMOUNT: res.varList[i].AMOUNT,
                                    ENTERLIRUNBIAO_ID: res.varList[i].ENTERLIRUNBIAO_ID,
                                    PROJECT_NAME: res.varList[i].PROJECT_NAME,
                                    PROJECT_PROPERTY: res.varList[i].PROJECT_PROPERTY,
                                    YEAR: res.varList[i].YEAR,
                                    childrenList: []
                                })
                            } else {
                                this.formLists[2].childrenList.push({
                                    AMOUNT: res.varList[i].AMOUNT,
                                    ENTERLIRUNBIAO_ID: res.varList[i].ENTERLIRUNBIAO_ID,
                                    PROJECT_NAME: res.varList[i].PROJECT_NAME,
                                    PROJECT_PROPERTY: res.varList[i].PROJECT_PROPERTY,
                                    YEAR: res.varList[i].YEAR
                                })
                            }
                        }
                    }
                    for (let i = k, len = res.varList.length; i < len; i++) {
                        if (res.varList[i].PROJECT_NAME === '五、净利润（净亏损以“-”号填列）') {
                            k = i
                            break
                        } else {
                            if (i == k) {
                                this.formLists.push({
                                    AMOUNT: res.varList[i].AMOUNT,
                                    ENTERLIRUNBIAO_ID: res.varList[i].ENTERLIRUNBIAO_ID,
                                    PROJECT_NAME: res.varList[i].PROJECT_NAME,
                                    PROJECT_PROPERTY: res.varList[i].PROJECT_PROPERTY,
                                    YEAR: res.varList[i].YEAR,
                                    childrenList: []
                                })
                            } else {
                                this.formLists[3].childrenList.push({
                                    AMOUNT: res.varList[i].AMOUNT,
                                    ENTERLIRUNBIAO_ID: res.varList[i].ENTERLIRUNBIAO_ID,
                                    PROJECT_NAME: res.varList[i].PROJECT_NAME,
                                    PROJECT_PROPERTY: res.varList[i].PROJECT_PROPERTY,
                                    YEAR: res.varList[i].YEAR
                                })
                            }
                        }
                    }
                    for (let i = k, len = res.varList.length; i < len; i++) {
                        if (i == k) {
                            this.formLists.push({
                                AMOUNT: res.varList[i].AMOUNT,
                                ENTERLIRUNBIAO_ID: res.varList[i].ENTERLIRUNBIAO_ID,
                                PROJECT_NAME: res.varList[i].PROJECT_NAME,
                                PROJECT_PROPERTY: res.varList[i].PROJECT_PROPERTY,
                                YEAR: res.varList[i].YEAR,
                                childrenList: []
                            })
                        } else {
                            this.formLists[4].childrenList.push({
                                AMOUNT: res.varList[i].AMOUNT,
                                ENTERLIRUNBIAO_ID: res.varList[i].ENTERLIRUNBIAO_ID,
                                PROJECT_NAME: res.varList[i].PROJECT_NAME,
                                PROJECT_PROPERTY: res.varList[i].PROJECT_PROPERTY,
                                YEAR: res.varList[i].YEAR
                            })
                        }
                    }
                    console.log(this.formLists,'formLists==========')

                } else {
                    this.$message.error('系统异常，获取列表失败，请稍后重试！')
                }
            })
        },
        onSave () {
            if (!this.isEdit) {
                this.$message.info('请先点击编辑后才能进行保存！')
                return false
            }
            let DATA_IDS = '', DATA_VALUES = ''
            for (let i in this.formLists) {
                DATA_IDS += this.formLists[i].ENTERLIRUNBIAO_ID + ','
                if (this.formLists[i].AMOUNT !== '' && this.formLists[i].AMOUNT) {
                    DATA_VALUES += this.formLists[i].AMOUNT + ','
                } else {
                    DATA_VALUES += '0,'
                }
                for (let k in this.formLists[i].childrenList) {
                    DATA_IDS += this.formLists[i].childrenList[k].ENTERLIRUNBIAO_ID + ','
                    if (this.formLists[i].childrenList[k].AMOUNT !== '' && this.formLists[i].childrenList[k].AMOUNT) {
                        DATA_VALUES += this.formLists[i].childrenList[k].AMOUNT + ','
                    } else {
                        DATA_VALUES += '0,'
                    }
                }
            }
            DATA_IDS = DATA_IDS.substring(0, DATA_IDS.length - 1)
            DATA_VALUES = DATA_VALUES.substring(0, DATA_VALUES.length - 1)
            this.$store.dispatch('saveLY', {
                DATA_IDS: DATA_IDS,
                DATA_VALUES: DATA_VALUES,
                tm: new Date().getTime(0)
            }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('保存成功！')
                    this.$store.commit('SET_ISEDIT', false)
                } else {
                    this.$message.error('系统异常，保存失败，请稍后重试！')
                }
            })
        }
    },
    created () {
        if (this.loginType === 'qiye' && this.isAuth === 'N') {
            this.$message.info('请先完成企业认证或个体认证后才能进入')
            this.$router.push('/PersonalLayout/WareHouse')
            return false
        }
        this.getLYList(this.y)
    }
}
</script>
<style lang="less" scoped>
.ant-form ::v-deep(label){
  font-size:12px;

}
::v-deep(.ant-form-item-label){
    overflow: visible;
}
::v-deep(.ant-form-item){
    margin-bottom: 10px !important;
}
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .con-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;

    }

    .pd-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .content{
            padding-top: 15px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            border-bottom: 1px solid #bbbbbb;
            .con-item{
                width: 55%;
            }
        }
    }
}
</style>
